import React from 'react';
/* eslint-disable */
export function VariantsFile(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M2.33333 13.1667C1.96667 13.1667 1.65278 13.0361 1.39167 12.775C1.13056 12.5139 1 12.2 1 11.8333V3.83333C1 3.46667 1.13056 3.15278 1.39167 2.89167C1.65278 2.63056 1.96667 2.5 2.33333 2.5H6.33333L7.66667 3.83333H13C13.3667 3.83333 13.6806 3.96389 13.9417 4.225C14.2028 4.48611 14.3333 4.8 14.3333 5.16667H7.11667L5.78333 3.83333H2.33333V11.8333H7V13.1667H2.33333Z"
        fill="#828299"
      />
      <path
        d="M6.66863 9.74351H8.56355C8.92496 9.74351 9.21021 9.69528 9.4193 9.59881C9.62839 9.50235 9.8291 9.34512 10.0214 9.12712L10.8036 9.90928C10.739 9.97691 10.6711 10.043 10.6 10.1077C10.5289 10.1723 10.4565 10.2349 10.3829 10.2956C10.4952 10.385 10.5987 10.4741 10.6935 10.563C10.7884 10.652 10.8824 10.7544 10.9757 10.8703C11.2305 11.197 11.5236 11.4641 11.8548 11.6715C12.186 11.8789 12.6646 11.9876 13.2905 11.9976L12.6401 11.3472L13.4118 10.5651L15.4023 12.5615L13.4118 14.552L12.6401 13.7802L13.2905 13.1194C12.3995 13.082 11.738 12.9253 11.3061 12.6493C10.8742 12.3734 10.4851 12.0248 10.1387 11.6036C9.91379 11.3343 9.69995 11.1409 9.4972 11.0236C9.29445 10.9063 8.98323 10.8477 8.56355 10.8477H6.66863V9.74351ZM12.3584 7.57276C12.4934 7.54106 12.6442 7.51706 12.8108 7.50075C12.9775 7.48444 13.1393 7.47478 13.2963 7.47176L12.6401 6.81099L13.4118 6.03334L15.4023 8.02974L13.4118 10.0202L12.6401 9.23808L13.2859 8.58772C13.1715 8.58772 13.0569 8.59368 12.9422 8.60561C12.8274 8.61754 12.7224 8.63241 12.627 8.65022L12.3584 7.57276ZM10.4956 8.59224C10.6493 8.4298 10.8243 8.27846 11.0208 8.13821C11.2172 7.99796 11.4342 7.87922 11.6718 7.782L11.9462 8.88437C11.8098 8.94596 11.6883 9.01699 11.5819 9.09746C11.4755 9.17792 11.3741 9.26828 11.2778 9.36852L10.4956 8.59224Z"
        fill="#828299"
      />
    </svg>
  );
}
