import React from 'react';
/* eslint-disable */
export function Variant(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M1.33073 7.33333L4.66406 7.33333C5.28629 7.33333 5.7474 7.23889 6.0474 7.05C6.3474 6.86111 6.64184 6.61111 6.93073 6.3L7.88073 7.25C7.75851 7.38333 7.62795 7.51111 7.48906 7.63333C7.35017 7.75556 7.20295 7.87778 7.0474 8C7.25851 8.15556 7.44462 8.31389 7.60573 8.475C7.76684 8.63611 7.92517 8.8 8.08073 8.96667C8.46962 9.38889 8.91962 9.77222 9.43073 10.1167C9.94184 10.4611 10.8363 10.6444 12.1141 10.6667L11.0641 9.61667L11.9974 8.66667L14.6641 11.3333L11.9974 14L11.0641 13.0667L12.1141 12C10.5252 11.9778 9.39462 11.7333 8.7224 11.2667C8.05017 10.8 7.50295 10.3333 7.08073 9.86667C6.75851 9.51111 6.44462 9.22222 6.13906 9C5.83351 8.77778 5.34184 8.66667 4.66406 8.66667L1.33073 8.66667L1.33073 7.33333ZM10.5474 4.13333C10.7696 4.08889 11.0141 4.05833 11.2807 4.04167C11.5474 4.025 11.8252 4.01111 12.1141 4L11.0641 2.93333L11.9974 2L14.6641 4.66667L11.9974 7.33333L11.0641 6.38333L12.0974 5.33333C11.8641 5.33333 11.6446 5.34444 11.4391 5.36667C11.2335 5.38889 11.0418 5.41111 10.8641 5.43333L10.5474 4.13333ZM7.61406 5.56667C7.8474 5.34444 8.11962 5.13056 8.43073 4.925C8.74184 4.71944 9.12517 4.53889 9.58073 4.38333L9.8974 5.66667C9.5974 5.77778 9.34184 5.90556 9.13073 6.05C8.91962 6.19444 8.73073 6.35 8.56406 6.51667L7.61406 5.56667Z"
        fill="#828299"
      />
    </svg>
  );
}
