import React from 'react';
/* eslint-disable */
export function PencilOutlined(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M3.89535 17C3.61757 17.0556 3.38493 16.9861 3.19743 16.7917C3.00993 16.5972 2.94396 16.3611 2.99952 16.0833L3.77035 12.375L7.60368 16.2292L3.89535 17ZM7.60368 16.2292L3.77035 12.375L12.7287 3.4375C13.0204 3.14583 13.371 3 13.7808 3C14.1905 3 14.5412 3.14583 14.8329 3.4375L16.562 5.14583C16.8537 5.4375 16.9995 5.79167 16.9995 6.20833C16.9995 6.625 16.8537 6.97917 16.562 7.27083L7.60368 16.2292ZM13.7704 4.5L5.64535 12.6458L7.35368 14.3542L15.4995 6.22917L13.7704 4.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
